/* Closest polyfill for IE11 */
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
  || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function x(s) {
    let el = this;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

/**
 * Make dropdown menus keyboard navigable
 * @param {string} element classname for the nav wrapper
 * @param {string} element classname for the <li> items
 */
function makeKeyboardNavigable(wrapper, item) {
  const nav = document.querySelector(wrapper);

  if (nav) {
    const links = [...nav.querySelectorAll('a')];
    links.forEach((link) => {
      const containerEl = link.closest(item);
      link.addEventListener('focus', () => {
        containerEl.classList.add('is-active');
      });
      link.addEventListener('blur', () => {
        containerEl.classList.remove('is-active');
      });
    });
  }
}

export default function init() {
  makeKeyboardNavigable('.js-top-header__nav', '.js-top-header__item');
}
