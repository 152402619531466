import backLink from '@modules/backLink';
import mobileNav from '@modules/mobileNav';
import desktopNav from '@modules/desktopNav';
import dropdownNav from '@modules/dropdownNav';
import searchBox from '@modules/searchBox';
import animateImages from '@modules/animateImages';
import animateStripes from '@modules/animateStripes';
import formieSubmission from '@modules/formieSubmission';
import 'focus-visible';

// Utilities
import '@styles/utilities/u-lazy.scss';
import '@styles/utilities/u-screen-reader.scss';

backLink();
mobileNav();
desktopNav();
dropdownNav();
searchBox();
animateImages();
animateStripes();
formieSubmission();
